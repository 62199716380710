import React from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import "./assets/styles/colors.scss";
import "./assets/styles/fonts.scss";
import "./assets/styles/style.scss";
import FooterContainer from "./components/navbar/footer/FooterContainer";
import NavbarTop from "./components/navbar/top/NavbarTop";
import { printConsoleInfoLog } from "./functions/console";

function App() {
  printConsoleInfoLog();
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <div style={{ overflowX: "hidden" }}>
          <NavbarTop />

          <div className="container mt-5 pt-5 mb-5 pb-5">
            <h1>Willkommen bei dashdesign!</h1>
            <div className="alert mt-4">
              Die Website wird aktuell überarbeitet. Die Marke wurde von der{" "}
              <a
                href="https://hessenapp.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                HessenApp GmbH
              </a>{" "}
              übernommen. Alle wichtigen Daten finden Sie auf der Website der Hessen App GmbH.
            </div>
            <a href="https://hessenapp.de" className="mb-5">
              <button className="btn btn-primary mt-3">Mehr erfahren</button>
            </a>
          </div>

          <FooterContainer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
